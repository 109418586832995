@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Naskh+Arabic:wght@500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  ---color: 161, 94, 54;
}
*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Rubik", serif;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
*::-webkit-scrollbar {
  width: 20px;
}

*::-webkit-scrollbar-track {
  background: #8c8c8c;
  border-radius: 20px;
  border: 5px solid #262626;

  /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: #262626; /* color of the scroll thumb */
  border-radius: 20px;
  border: 7px solid #262626;
}
.pulse {
  position: relative;
  z-index: 2;
}
.p {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: rgba(229, 62, 62, 0.5);
  border-radius: 4px;
  animation: pus 2s infinite;
}
.p {
  z-index: 1;
}
@keyframes pus {
  0% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0px rgba(var(---color), 1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(var(---color), 0);
  }

  100% {
    transform: scale(0.8);
  }
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #002fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
