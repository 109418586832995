.Cont{
    background-color: 	#d9dfdb;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loginCard{
    height: 40rem;
    width: 25rem;
    box-shadow: 1px 1px 24px #24222227;
    border-radius: 25px;
    padding: 7rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}
