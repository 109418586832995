.wraper{
   
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.c{
    background-color: 	#d9dfdb;
    position: relative;
    height: 25rem;
    width: 25rem;
    box-shadow: 1px 1px 24px #24222227;
    border-radius: 25px;
    padding: 7rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}
.points{
    position: absolute;
    top: 2rem
}
.b{
    position: absolute;
    bottom: 2rem;
    padding: 0 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.history{
    position: absolute;
    right: 1rem;
    width: 20%;
    height: 90%;
    background-color: 	#d9dfdb;
    padding: 1rem;
    border-radius: 15px;
    overflow-y: auto;


}
.item{
    border-radius: 15px;
    height: 4rem;
    padding: 1rem;
    box-shadow: 1px 1px 24px rgba(63, 62, 62, 0.2);
    margin-bottom: 1rem;
text-align: center;
}