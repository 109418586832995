.expand{
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.header{
    height: 5rem;
    display: flex;
    align-items: center;
    padding: 2rem;
    justify-content: flex-end;
}
.wrap{
    padding: 1rem;
}
.input{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap:10px

}
.form{
    height: 100%;
}